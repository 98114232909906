import { LOCALES } from '../locales';
const translation = {
  [LOCALES.SPANISH]: {
    welcomeBack: 'Hola, Bienvenido',

    //fields
    firstName: 'Nombres',
    lastName: 'Apellidos',
    fullName: 'Nombre Completo',
    email: 'Correo Electrónico',
    telephone: 'Móvil',
    country: 'País',
    password: 'Clave',
    message: 'Mensaje',
    rubric: 'Rubro',

    // buttons
    loginBtn: 'Entrar',
    registerBtn: 'Registrarme',
    send: 'Enviar',

    add: 'Agregar',
    addNew: 'Nuevo',
    edit: 'Editar',
    save: 'Guardar',
    cancel: 'Cancelar',
    return: 'Volver',
    delete: 'Borrar',
    sendToVerify: 'Para Verificar',
    refresh: 'Refrescar',

    actions: ' ',

    // validations errors
    required: 'Requerido',
    invalidEmail: 'Ingrese un Email valido',
    max50caracteres: 'No puede pasar 50 caracteres',
    loginFailed: 'Correo Electrónico o clave incorrecto. Intente otra vez.',
    sendContactFailed: 'Hubo un error al intentar enviar su mensaje. Por favor, inténtelo de nuevo más tarde.',

    // mainNavbar
    myCurrentInquiry: 'Mi Cotización',
    aboutUs: '¿Quiénes somos?',
    blogPosts: 'Blogs & Novedades',
    products: 'Productos',
    contactUs: 'Contáctanos',

    all: 'Todos',
    quotetoVerify: 'Verificar',
    quoteToDo: 'Pendientes',
    quoteInProcess: 'En Progreso',
    quoteFinished: 'Finalizado',
    
    // sidebar menu
    //groups
    overview: 'Resumen',
    general: 'General',
    ecommerce: 'E-Comercio',
    management: 'Gestionar',

    dashboard: 'Principal',
    categories: 'Categorías',
    suppliers: 'Proveedores',
    brands: 'Marcas',
    blog: 'Blog',
    users: 'Usuarios',
    roles: 'Grupos',
    orders: 'Pedidos Cliente', //Ordenes',
    
    ordersEmployee: 'Mis Pedidos', //Ordenes',

    supplier: 'Proveedor',
    brand: 'Marca',
    user: 'Usuario',
    role: 'Grupo',
    order: 'Orden',
    myOrder: 'My orden',

    topProducts: 'Top Productos',
    catalogs: 'Catálogos',
    catalog: 'Catálogo',

    // filter bar
    filters: 'Filtros',
    gender: 'Género',
    category: 'Categoría',
    colors: 'Colores',
    men: 'Hombre',
    women: 'Mujer',
    kids: 'Niños',
    all: 'Todos',
    accesories: 'Accesorios',
    price: 'Precio',
    below: 'Menor',
    rating: 'Rating',
    up: 'Mas',
    clearAll: 'Limpiar Todo',

    sortBy: 'Ordenar por',
    featured: 'Destacado',
    newest: 'Nuevos',
    priceHighLow: 'Precio: Mayor-Menor',
    priceLowHigh: 'Precio: Menor-Mayor',



    // header
    haveAccountQuestion: '¿No tienes una cuenta?',
    haveAlreadyAccountQuestion: '¿Tiene una cuenta?',
    signupLink: 'Registrate',
    signinLink: 'Inicia Sesión',

    // login
    login: 'Iniciar Sesión',
    loginWelcome: '¡Hola, Te Damos La Bienvenida!',
    loginTitle: 'Portal GoComercio App',
    loginDescription: 'Ingrese sus detalles',
    
    // register
    register: 'Registrar',
    registerFailed: 'Registro Fallido',
    registerEnviado: 'Registro Enviado',
    registerConfirmation: 'Confirmación de Registro',
    registerWelcome: 'Administre su requerimiento efectivamente',
    registerTitle: 'GoComercio Absolutamente Gratis',
    registerDescription: 'Registro. No necesita tarjeta de credito.',

    // account menu
    profile: 'Perfil',
    inquiries: 'Consultas',
    settings: 'Configuración',
    signout: 'Cerrar Sesión',

    // Dashboard
    home: 'Inicio',
    // blog: 'Blog & Novedades',

    productName: 'Producto',
    remarks: 'Notas Cliente',
    remarksQuotation: 'Notas',
    remarksOrder: 'Notas',

    pageNotFound: '404 Page Not Found',
    myInquiries: '',
    myWishedOrder: 'Mis Pedidos Deseados',

    newUsers: 'Nuevos Usuarios',
    newInquiries: 'Consultas',
    newOrders: 'Pedidos',
    newRequests: 'Solicitudes',
    productsBugReport: 'Reporte Error',

    // Users
    phoneNumber: 'Móvil',
    emailConfirmed: 'Estado',
    roleFk: 'Grupo',
    active: 'Activo',

    // Roles
    name: 'Nombre',
    admin: 'Admin',
    sales: 'Ventas',
    data: 'Datos',

    // Role Permission
    groupPermission: 'Grupo Permisos',

    nameInfo: 'Nombre',
    read: 'Ver',
    create: 'Agregar',
    update: 'Guardar',

    // dashboard: ''
    productManage: 'Modulo Basico',
    product: 'Producto',
    // catalog: 'Modulo Basico',
    // brand: 'Marca',
    provider: 'Proveedor',
    userManage: 'Modulo Usuario',
    orderManage: 'Modulo Orden',
    orderFinished: 'Orden Terminada',

    // order: '',
    inquiry: 'Consulta', //'Cotizacion',

    // Category
    descriptionInfo: 'Descripcion',
    slug: 'Url',
    isActive: 'Activo',

    // Suppliers
    contactPersonName: 'Contacto',
    address: 'Direccion',
    
    // Products
    isProductActive: 'Publico',
    serialNumber: 'Serie',
    imgPath: 'Imagen',
    detailImgPath: 'Imagen',
    marketPrice: 'Precio',
    memberPrice: 'Precio Membresía',//Membresía',
    comparePrice: 'Precio Venta Destino',
    categoryFk: 'Categoría',
    supplierFk: 'Proveedor',
    brandFk: 'Marca',

    stock: 'Stock',

    // Inquiry
    aspNetUsersFk: 'Cliente', //'Usuario',
    orderStateFk: 'Cotización',
    responsibleUserFk: 'Responsable',
    workStateFk: 'Estado',
    workStateSpan: 'Días Restantes',
    itemCount: 'Cantidad',
    itemCost: 'Precio',
    totalCost: 'Total',
    useDiscountTotal: 'Usar Descuento',
    discountTotal: 'Total Con Descuento',
    userDefinedTitle: 'Título De Cotización',
    // definedTitle: '',

    assignTo: 'Asignar',
    exportTo: 'Exportar',
    copy: 'Copiar',

    //Inquiry
    //orders: Pedidos
    inquiriesVerify: 'Consultas Verificar',
    inquiriesPending: 'Consultas Recibidas',
    inquiriesProcessing: 'Consultas Proceso',
    inquiriesDone: 'Consultas Finalizadas',

    inquiryProcessing: 'Consulta Proceso',

    inquiryCheckPassed: 'Confirmado',
    inquiryCheckFailed: 'Fallido',

    basic: 'Basico',
    // Detail
    shippingName: 'Nombre Envio',
    shippingCode: 'Codigo Envio',
    shippingCost: 'Costo Envio',

    //Orders
    //orders: Pedidos
    ordersVerify: 'Pedidos Verificar',
    ordersPending: 'Pedidos Recibidos',
    ordersProcessing: 'Pedidos En Progreso',
    ordersDone: 'Pedidos Finalizados',

    //My orders
    myOrdersVerify: 'Mis Pedidos Verificar',
    myOrdersPending: 'Mis Pedidos Recibidos',
    myOrdersProcessing: 'Mis Pedidos En Progreso',
    myOrdersDone: 'Mis Pedidos Finalizados',


    orderCheckPassed: 'Confirmado',
    orderCheckFailed: 'Fallido',

    productFk: 'Producto',
    productNameInfo: 'Producto',
    productImgPath: 'Imagen',
    count: 'Cantidad',
    

    // States
    orderState1: 'Para Cotizar',
    orderState2: 'Cotizando',
    orderState3: 'Cotizado',
    orderState4: 'Cancelado',

    //Enquiry work state
    changeWorkState: 'Cambiar Estado',
    workState1: 'Pendiente',
    workState2: 'En Progreso',
    workState3: 'Cotizado',
    workState4: 'Cancelado',

    //Catalog states
    catalogState1: 'Para Agregar',
    catalogState2: 'En Progreso',
    catalogState3: 'Listo',
    catalogState4: 'Cancelado',

    // Inquiry Order Item Detail
    quantity: 'Cantidad',
    // totalCost: 'Total'
    discountPrice: 'Descuento',
    useDiscountPrice: 'Usar Descuento',
    packing: 'Empaquetado',
    packingSize: 'Tamano de paquete',
    uomFk: 'Unidad de Medida',
    netWeight: 'Peso neto',
    weight: 'Peso bruto',
    volume: 'Volumen',
    
    //History data
    createdBy: 'Creado Por',
    createdOn: 'Creado En',
    updatedBy: 'Modificado Por',
    updatedOn: 'Modificado En',


    //Others
    customer: 'Cliente',

    
  },
};
export default translation;
