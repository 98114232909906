//https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src%2FApp.tsx

import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { authProvider } from "./AuthProvider";
import { User } from "./user";

interface AuthContextType {
    roles: string[];
    user: any;
    userId: number;
    signin: (user: string, password: string, callback: Function) => void;
    signout: (callback: VoidFunction) => void;
    loading: Boolean;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    let [userId, setUserId] = React.useState<number>(-1);
    let [user, setUser] = React.useState<User | null>(null);
    let [roles, setRoles] = React.useState<string[]>([]);

    let [loading, setLoading] = React.useState<Boolean>(true);


    let signin = async (newUser: string, password: string, callback: Function) => {
        const success = await authProvider.signin(newUser, password, callback);
        // setUser(success ? newUser: null);
        // setUser(newUser);

        if (!success) {
            setUser(null);
        }

        //   return fakeAuthProvider.signin(() => {
        //     setUser(newUser);
        //     callback();
        //   });
    };

    let signout = (callback: VoidFunction) => {
        return authProvider.signout(() => {
            setUser(null);
            callback();
        });
    };

    let value = { user, userId, roles, signin, signout, loading };

    useEffect(() => {
        let currentUser = localStorage.getItem('currentUser');
        if (typeof currentUser === 'string') {
            let currentUserParsed: User = JSON.parse(currentUser);

            // setUser(currentUserParsed.userInfo.lastName);
            setUser(currentUserParsed.userInfo);
            setUserId(currentUserParsed.userInfo.id);

            setRoles(currentUserParsed.roles) ;

        } else {
            setUser(null);
            setUserId(-2);
            setRoles([]);
        }

        setLoading(false);

        return () => {
            // setUser(null);
            // setUserId(-2);
            // setRoles([]);
            setLoading(false);
        }
    }, []); //loading

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default function useAuth() {
    return React.useContext(AuthContext);
}

// function AuthStatus() {
//     let auth = useAuth();
//     let navigate = useNavigate();

//     if (!auth.user) {
//         return <p>You are not logged in.</p>;
//     }

//     return (
//         <p>
//             Welcome {auth.user}!{" "}
//             <button
//                 onClick={() => {
//                     auth.signout(() => navigate("/"));
//                 }}
//             >
//                 Sign out
//             </button>
//         </p>
//     );
// }

export function RequireAuth({ children }: { children: JSX.Element }) {
    // let auth = useAuth();//TODO-VICTOR: 
    let location = useLocation();

    // if (!auth.user) {
    if (!localStorage.getItem('token')) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        // return <Navigate to="/login" state={{ from: location }} replace />;
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
}