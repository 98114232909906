import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { RequireAuth } from './auth/useAuth';

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(
        'page-has-been-force-refreshed'
      ) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        'page-has-been-force-refreshed',
        'false'
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(
          'page-has-been-force-refreshed',
          'true'
        );
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

const LogoOnlyLayout = lazyWithRetry(()=>import('../../app/layouts/LogoOnlyLayout'));
const DashboardLayout = lazyWithRetry(()=>import('../../app/layouts/dashboard'));


const Login = lazyWithRetry(()=>import('../../app.account/login-view'));

const Home= lazyWithRetry(()=>import('../../portal.home'));

const ProductList= lazyWithRetry(()=>import('../../product.main'));
const ProductEdit= lazyWithRetry(()=>import('../../product.main/edit-view'));
const ProductTopList= lazyWithRetry(()=>import('../../product.top'));

const CatalogList= lazyWithRetry(()=>import('../../catalog.main'));
const CatalogEdit= lazyWithRetry(()=>import('../../catalog.main/edit-view'));
const CatalogEditOrderItem= lazyWithRetry(()=>import('../../catalog.main/edit-order-item'));

const BrandList= lazyWithRetry(()=>import('../../brand.main'));
const BrandEdit= lazyWithRetry(()=>import('../../brand.main/edit-view'));

const CategoryList= lazyWithRetry(()=>import('../../category.main'));
const CategoryEdit= lazyWithRetry(()=>import('../../category.main/edit-view'));

const SupplierList= lazyWithRetry(()=>import('../../supplier.main'));
const SupplierEdit= lazyWithRetry(()=>import('../../supplier.main/edit-view'));

const UserList= lazyWithRetry(()=>import('../../usermanagement.main'));
const UserEdit= lazyWithRetry(()=>import('../../usermanagement.main/edit-view'));

const RoleList= lazyWithRetry(()=>import('../../usermanagement.group'));
const RoleEdit= lazyWithRetry(()=>import('../../usermanagement.group/edit-view'));

const InquiryList= lazyWithRetry(()=>import('../../inquiry.main'));

const InquiryDetail= lazyWithRetry(()=>import('../../inquiry.main/detail-view'));
// //Detail
// const InquiryEdit= lazyWithRetry(()=>import('../../inquiry.main/edit-view'));
const InquiryVerify= lazyWithRetry(()=>import('../../inquiry.verify'));
const InquiryPending= lazyWithRetry(()=>import('../../inquiry.pending'));

const InquiryProcessing= lazyWithRetry(()=>import('../../inquiry.processing'));
const InquiryProcessingEdit= lazyWithRetry(()=>import('../../inquiry.processing/edit-view'));
const InquiryProcessingEditOrderItem= lazyWithRetry(()=>import('../../inquiry.processing/edit-order-item'));

const InquiryDone= lazyWithRetry(()=>import('../../inquiry.done'));

const OrderDetail= lazyWithRetry(()=>import('../../order.main/detail-view'));

const OrderList= lazyWithRetry(()=>import('../../order.main'));
const OrderVerify= lazyWithRetry(()=>import('../../order.verify'));
const OrderVerifyDetail= lazyWithRetry(()=>import('../../order.verify/detail-view'));
const OrderPending= lazyWithRetry(()=>import('../../order.pending'));
const OrderProcessing= lazyWithRetry(()=>import('../../order.processing'));
const OrderProcessingEdit= lazyWithRetry(()=>import('../../order.processing/edit-view'));
const OrderProcessingEditOrderItem= lazyWithRetry(()=>import('../../order.processing/edit-order-item'));
const OrderDone= lazyWithRetry(()=>import('../../order.done'));


const OrderEmployeeDetail= lazyWithRetry(()=>import('../../order.main/detail-view'));

const OrderEmployeeList= lazyWithRetry(()=>import('../../order.employee'));
const OrderEmployeeVerify= lazyWithRetry(()=>import('../../order.verify'));
const OrderEmployeeVerifyDetail= lazyWithRetry(()=>import('../../order.verify/detail-view'));
const OrderEmployeePending= lazyWithRetry(()=>import('../../order.pending'));
const OrderEmployeeProcessing= lazyWithRetry(()=>import('../../order.processing'));
const OrderEmployeeProcessingEdit= lazyWithRetry(()=>import('../../order.processing/edit-view'));
const OrderEmployeeProcessingEditOrderItem= lazyWithRetry(()=>import('../../order.processing/edit-order-item'));
const OrderEmployeeDone= lazyWithRetry(()=>import('../../order.done'));



const NotFound= lazyWithRetry(()=>import('../../app.404'));


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <RequireAuth><Suspense fallback={<div>Cargando...</div>}><DashboardLayout /></Suspense></RequireAuth>,
      children: [
        { path: '', element: <Suspense fallback={<div>Cargando...</div>}><Home /></Suspense> },
      ],
    },
    {
      path: '/top-products',
      element: <RequireAuth><Suspense fallback={<div>Cargando...</div>}><DashboardLayout /></Suspense></RequireAuth>,
      children: [
        { path: '', element: <Suspense fallback={<div>Cargando...</div>}><ProductTopList /></Suspense> }
      ],
    },
    {
      path: '/catalogs',
      element: <RequireAuth><Suspense fallback={<div>Cargando...</div>}><DashboardLayout /></Suspense></RequireAuth>,
      children: [
        { path: '', element: <Suspense fallback={<div>Cargando...</div>}><CatalogList /></Suspense> },
        { path: ':id', element: <Suspense fallback={<div>Cargando...</div>}><CatalogEdit returnUrl="/catalogs" /></Suspense> },
        { path: ':id/product', element: <Suspense fallback={<div>Cargando...</div>}><ProductList isCatalog={true} returnUrl="/catalogs" /></Suspense> },
        { path: ':id/product/:orderItemId', element: <Suspense fallback={<div>Cargando...</div>}><CatalogEditOrderItem returnUrl="/catalogs" /></Suspense> },
      ],
    },
    {
      path: '/admin',
      element: <RequireAuth><Suspense fallback={<div>Cargando...</div>}><DashboardLayout /></Suspense></RequireAuth>,
      children: [
        { path: 'users', element: <Suspense fallback={<div>Cargando...</div>}><UserList /></Suspense> },
        { path: 'users/add', element: <Suspense fallback={<div>Cargando...</div>}><UserEdit /></Suspense> },
        { path: 'users/edit/:id', element: <Suspense fallback={<div>Cargando...</div>}><UserEdit /></Suspense> },

        { path: 'roles', element: <Suspense fallback={<div>Cargando...</div>}><RoleList /></Suspense> },
        { path: 'roles/edit/:id', element: <Suspense fallback={<div>Cargando...</div>}><RoleEdit /></Suspense> },
      ]
    },
    {
      path: '/basic',
      element: <RequireAuth><Suspense fallback={<div>Cargando...</div>}><DashboardLayout /></Suspense></RequireAuth>,
      children: [
        { path: 'products', element: <Suspense fallback={<div>Cargando...</div>}><ProductList /></Suspense> },
        { path: 'products/add', element: <Suspense fallback={<div>Cargando...</div>}><ProductEdit /></Suspense> },
        { path: 'products/edit/:id', element: <Suspense fallback={<div>Cargando...</div>}><ProductEdit /></Suspense> },

        { path: 'brands', element: <Suspense fallback={<div>Cargando...</div>}><BrandList /></Suspense> },
        { path: 'brands/add', element: <Suspense fallback={<div>Cargando...</div>}><BrandEdit /></Suspense> },
        { path: 'brands/edit/:id', element: <Suspense fallback={<div>Cargando...</div>}><BrandEdit /></Suspense> },

        { path: 'categories', element: <Suspense fallback={<div>Cargando...</div>}><CategoryList /></Suspense> },
        { path: 'categories/add', element: <Suspense fallback={<div>Cargando...</div>}><CategoryEdit /></Suspense> },
        { path: 'categories/edit/:id', element: <Suspense fallback={<div>Cargando...</div>}><CategoryEdit /></Suspense> },

        { path: 'suppliers', element: <Suspense fallback={<div>Cargando...</div>}><SupplierList /></Suspense> },
        { path: 'suppliers/add', element: <Suspense fallback={<div>Cargando...</div>}><SupplierEdit /></Suspense> },
        { path: 'suppliers/edit/:id', element: <Suspense fallback={<div>Cargando...</div>}><SupplierEdit /></Suspense> },
      ],
    },
    //TODO-VICTOR
    {
      path: '/quotation',
      element: <RequireAuth><Suspense fallback={<div>Cargando...</div>}><DashboardLayout /></Suspense></RequireAuth>,
      children: [
        { path: 'inquiries/all', element: <Suspense fallback={<div>Cargando...</div>}><InquiryList /></Suspense> },
        { path: 'inquiries/all/detail/:id', element: <Suspense fallback={<div>Cargando...</div>}><InquiryDetail returnUrl="/quotation/inquiries/all" /></Suspense> },
        { path: 'inquiries/verify', element: <Suspense fallback={<div>Cargando...</div>}><InquiryVerify /></Suspense> },
        { path: 'inquiries/verify/detail/:id', element: <Suspense fallback={<div>Cargando...</div>}><OrderVerifyDetail returnUrl="/quotation/inquiries/verify" /></Suspense> },
        { path: 'inquiries/pending', element: <Suspense fallback={<div>Cargando...</div>}><InquiryPending /></Suspense> },
        { path: 'inquiries/process', element: <Suspense fallback={<div>Cargando...</div>}><InquiryProcessing /></Suspense> },
        { path: 'inquiries/process/:id', element: <Suspense fallback={<div>Cargando...</div>}><InquiryProcessingEdit returnUrl="/quotation/inquiries/process" /></Suspense> },
        { path: 'inquiries/process/:id/product', element: <Suspense fallback={<div>Cargando...</div>}><ProductList returnUrl="/inquiries-process/edit/" /></Suspense> },
        { path: 'inquiries/process/:id/product/:orderItemId', element: <Suspense fallback={<div>Cargando...</div>}><InquiryProcessingEditOrderItem /></Suspense> },
        { path: 'inquiries/done', element: <Suspense fallback={<div>Cargando...</div>}><InquiryDone /></Suspense> },
      ],
    },
    {
      path: '/manage',
      element: <RequireAuth><Suspense fallback={<div>Cargando...</div>}><DashboardLayout /></Suspense></RequireAuth>,
      children: [
        { path: 'orders/all', element: <Suspense fallback={<div>Cargando...</div>}><OrderList /></Suspense> },
        { path: 'orders/all/detail/:id', element: <Suspense fallback={<div>Cargando...</div>}><OrderDetail returnUrl="/manage/orders/all" /></Suspense> },
        { path: 'orders/verify', element: <Suspense fallback={<div>Cargando...</div>}><OrderVerify /></Suspense> },
        { path: 'orders/verify/detail/:id', element: <Suspense fallback={<div>Cargando...</div>}><OrderVerifyDetail returnUrl="/manage/orders/verify" /></Suspense> },
        { path: 'orders/pending', element: <Suspense fallback={<div>Cargando...</div>}><OrderPending /></Suspense> },
        { path: 'orders/process', element: <Suspense fallback={<div>Cargando...</div>}><OrderProcessing /></Suspense> },
        { path: 'orders/process/:id', element: <Suspense fallback={<div>Cargando...</div>}><OrderProcessingEdit returnUrl="/manage/orders/process" /></Suspense> },
        { path: 'orders/process/:id/product', element: <Suspense fallback={<div>Cargando...</div>}><ProductList returnUrl="/orders/process" /></Suspense> },
        { path: 'orders/process/:id/product/:orderItemId', element: <Suspense fallback={<div>Cargando...</div>}><OrderProcessingEditOrderItem /></Suspense> },
        { path: 'orders/done', element: <Suspense fallback={<div>Cargando...</div>}><OrderDone /></Suspense> },

        { path: 'my-orders/all', element: <Suspense fallback={<div>Cargando...</div>}><OrderEmployeeList /></Suspense> },
        { path: 'my-orders/all/detail/:id', element: <Suspense fallback={<div>Cargando...</div>}><OrderEmployeeDetail returnUrl="/manage/my-orders/all" /></Suspense> },
        { path: 'my-orders/verify', element: <Suspense fallback={<div>Cargando...</div>}><OrderEmployeeVerify isEmployeeOrder={true} /></Suspense> },
        { path: 'my-orders/verify/detail/:id', element: <Suspense fallback={<div>Cargando...</div>}><OrderEmployeeVerifyDetail returnUrl="/manage/my-orders/verify" /></Suspense> },
        { path: 'my-orders/pending', element: <Suspense fallback={<div>Cargando...</div>}><OrderEmployeePending isEmployeeOrder={true} /></Suspense> },
        { path: 'my-orders/process', element: <Suspense fallback={<div>Cargando...</div>}><OrderEmployeeProcessing isEmployeeOrder={true} /></Suspense> },
        { path: 'my-orders/process/:id', element: <Suspense fallback={<div>Cargando...</div>}><CatalogEdit isEmployeeOrder={true} returnUrl="/manage/my-orders/process" /></Suspense> },
        { path: 'my-orders/process/:id/product', element: <Suspense fallback={<div>Cargando...</div>}><ProductList isEmployeeOrder={true}  returnUrl="/manage/my-orders/process" /></Suspense> },
        { path: 'my-orders/process/:id/product/:orderItemId', element: <Suspense fallback={<div>Cargando...</div>}><OrderEmployeeProcessingEditOrderItem /></Suspense> },
        { path: 'my-orders/done', element: <Suspense fallback={<div>Cargando...</div>}><OrderEmployeeDone isEmployeeOrder={true} /></Suspense> },
      ],
    },
    
    {
      path: '/',
      element: <Suspense fallback={<div>Cargando...</div>}><LogoOnlyLayout /></Suspense>,
      children: [
        { path: '', element: <Suspense fallback={<div>Cargando...</div>}><Login /></Suspense> },
        { path: '404', element: <Suspense fallback={<div>Cargando...</div>}><NotFound /> </Suspense>},
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}
