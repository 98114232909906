function AccessRightService() {
    this.isSet = false;
    this.accessRights = [];
    this.set = function (value) {
        this.isSet = true;
        this.accessRights = value;
    };
    this.get = function () {
        return this.isSet ? this.accessRights : JSON.parse(localStorage.getItem("AccessRights"));
    };
    this.hasPermission = function(guid, mask, defaultValue){
        let accessRights = this.get();

        if (!guid || !accessRights || accessRights.length === 0) return defaultValue;

        let currentItem = accessRights.find(e => e.guid == guid);

        if (currentItem) {
            return (currentItem.value & mask) > 0 ? true : false;
        } else {
            return defaultValue;
        }
    };
    this.hasRead = function (guid) {
        return this.hasPermission(guid, 0x1, true);
    };
    this.hasCreate = function (guid) {
        return this.hasPermission(guid, 0x2, false);
    };
    this.hasUpdate = function (guid) {
        return this.hasPermission(guid, 0x4, false);
    };
    this.hasDelete = function (guid) {
        return this.hasPermission(guid, 0x8, false);
    };
}

const accessRightService = new AccessRightService();

export default accessRightService;
