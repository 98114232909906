import http from '../../utils/http';
import { User } from './user';

import accessRightService from '../../services/access-right-service';
/**
 * This represents some generic auth provider API, like Firebase.
 */

const accountLoginUrl = 'AccAccount/LoginForManager';

const authProvider = {
    isAuthenticated: false,
    async signin(username: string, password: string, callback: Function): Promise<boolean> {
        const { data } = await http.post<User>(accountLoginUrl, { username, password });

        if (data && data.jwtToken) {
            localStorage.setItem('currentUserId', data.userInfo.id);
            localStorage.setItem('currentUser', JSON.stringify(data));
            localStorage.setItem('token', data.jwtToken);
            localStorage.setItem("AccessRights", JSON.stringify(data.accessRights));

            //set accessRights
            accessRightService.set(data.accessRights);


            authProvider.isAuthenticated = true;
        }
    
        //workaround
        callback(data);

        return authProvider.isAuthenticated;
    },
    signout(callback: VoidFunction) {
        localStorage.removeItem('currentUserId');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.removeItem("AccessRights");
        authProvider.isAuthenticated = false;
        // setTimeout(callback, 100);

        window.location.href = '/'; //workdaround, make
    }
};

export { authProvider };