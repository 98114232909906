// import en from './en'
// import zh from './zh'
import es from './es';

const translations =  {
    ...es
    // ...en,
    // ...zh
}

export default translations;